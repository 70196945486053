import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { CameraControlsReference } from '../components/Three/controls/ThreeControls';
import { FIFOState } from '../components/Three/FIFO';

export const threeLoadedAtom = atom(false);

// controls
export const movementInteractableAtom = atom(false);
export const controlsAtom = atom<CameraControlsReference | undefined>(undefined);
export const controlsEnabledAtom = atom(true); // use this to determine wether or not the user's input is registered
export const activeMovementPositionIdAtom = atom('5');
export const gameFlowStepAtom = atom(0);

// debug atoms

export const debugPerformanceAtom = atomWithStorage('debug_performance', false);
export const debugCameraAtom = atom(false); // use this to change the restrictions placed on the camera

// FIFO

export const FIFOStateAtom = atom<FIFOState>(0);