import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle1_03: DialogueData = {
	name: 'Dispatch',

	messages: { 
		
		// start message         
		'dialogue_puzzle1_03_00':{
			speakingCharacter: -1,
			followupMessage: 'dialogue_puzzle1_03_01'
		},

		'dialogue_puzzle1_03_01':{
			speakingCharacter: 0,
		},

	}
};

export default dialogue_puzzle1_03;