import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle3_05: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'set-step', payload:{stepId: 1}},
	],
	messages: { 
		
		// start message         
		'dialogue_puzzle3_05_00':{
			speakingCharacter: -1,
			followupMessage: 'dialogue_puzzle3_05_01'
		},
		'dialogue_puzzle3_05_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle3_05_02'
		},
		'dialogue_puzzle3_05_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle3_05_03'
		},
		'dialogue_puzzle3_05_03':{
			speakingCharacter: 0,
		},
	}
};

export default dialogue_puzzle3_05;