import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle1_01: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'set-step', payload:{stepId: 2}},
	],
	messages: { 
		
		// start message         
		'dialogue_puzzle1_01_00':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_puzzle1_01_01'},
				{followupMessage: 'dialogue_puzzle1_01_02'},
			]
		},

		'dialogue_puzzle1_01_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_03',

		},
		'dialogue_puzzle1_01_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_03',
		},
		'dialogue_puzzle1_01_03':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_04',
		},

		'dialogue_puzzle1_01_04':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_05'
		},

		'dialogue_puzzle1_01_05':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_puzzle1_01_06'},
				{followupMessage: 'dialogue_puzzle1_01_07'},
			]
		},


		'dialogue_puzzle1_01_06':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_08',
		},

		'dialogue_puzzle1_01_07':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_01_08',
		},

		'dialogue_puzzle1_01_08':{
			speakingCharacter: 0,
		},
	}
};

export default dialogue_puzzle1_01;