import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle2_06: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle2_06_00':{
			speakingCharacter: -1,
			followupMessage: 'dialogue_puzzle2_06_01'
		},

		'dialogue_puzzle2_06_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle2_06_02'
		},
		'dialogue_puzzle2_06_02':{
			speakingCharacter: 0,
		},


	}
};

export default dialogue_puzzle2_06;