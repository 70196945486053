
import { DialogueData } from '../atoms/dialogue';
import dialogues from './dialogues/dialogues-scenario1';

export const gameData: GameData = {
	dialogues: dialogues,
};

export type GameData = {
	dialogues: {[key: string]: DialogueData}
}