import { atomWithReducer } from 'jotai/utils';
/**
 * Reducer that handles events to be triggered in a FIFO transition.
 * @param state current value of the state.
 * @param action action given to be handled.
 * @returns 
 */
const eventQueueReducer = (state: EventQueue, action: EventQueueActions) => {
	switch(action.type){
	case 'add':
		state = eventQueueAdd(state, action);
		return [...state];
	case 'remove':
		state = eventQueueRemove(state, action);
		return [...state];
	case 'clear':
		return [];
	}
};

const eventQueueAdd = (state: EventQueue, action:EventQueueActionAdd) : EventQueue => {
	const newEvent : EventQueueEntry = action.payload;
	const searchEventId = state.findIndex((e) => 
		e.eventType === action.payload.eventType && e.parameter === action.payload.parameter
	);
	if(searchEventId !== -1) return state;
	state.push(newEvent);
	return state;
};

const eventQueueRemove = (state: EventQueue, action:EventQueueActionRemove) : EventQueue => {
	const searchEventId = state.findIndex((e) => 
		e.eventType === action.payload.eventType && e.parameter === action.payload.parameter
	);
	if(searchEventId === -1) return state;
	state.splice(searchEventId, 1);
	return state;
};


/**
 * Atom that handles events to be triggered in a FIFO transition.
 */
export const eventQueueAtom = atomWithReducer<EventQueue, EventQueueActions>([],eventQueueReducer);

// types

type EventQueue = EventQueueEntry[];
type EventQueueEntry = EventQueuePayloadBasic;

export type EventType = 
	'set-movement-position' |
	'toggle-object-visibility'| 
	'proceed-gameflow'| 
	'set-porto' | 
	'set-dialogue'
;

type EventQueueActions = EventQueueActionAdd | EventQueueActionRemove | EventQueueActionClear;

type EventQueueActionAdd = {
	type: 'add',
	payload: EventQueuePayloadBasic;
}

type EventQueueActionRemove = {
	type: 'remove',
	payload: EventQueuePayloadBasic;
}

export type EventQueuePayloadBasic = {
	eventType: EventType,
	parameter: string,
}

type EventQueueActionClear = {
	type: 'clear',
}


