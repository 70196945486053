import { useTranslation } from 'react-i18next';
import { resources } from '../../../localization/i18n';

const DCLocalization = () => {

	const {t, i18n} = useTranslation();

	return (
		<>
			{Object.keys(resources).map((lng) => {
				return (<button disabled={i18n.resolvedLanguage === lng} onClick={() => i18n.changeLanguage(lng)} key={lng}>{lng}</button>);
			})}
		</>
	);
};
 
export default DCLocalization;