import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle3_02: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle3_02_00':{
			speakingCharacter: 0,
			// followupMessage: 'dialogue_puzzle3_02_02'
		},
		// 'dialogue_puzzle3_02_02':{
		// 	speakingCharacter: 0,
		// },
	}
};

export default dialogue_puzzle3_02;