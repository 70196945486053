import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle1_02: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'set-step', payload:{stepId: 3}},
	],
	messages: { 
		
		// start message         
		'dialogue_puzzle1_02_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_02_01'
		},

		'dialogue_puzzle1_02_01':{
			speakingCharacter: 0,			
			answers: [
				{followupMessage: 'dialogue_puzzle1_02_02'},
				{followupMessage:'dialogue_puzzle1_02_04'}
			]
		},
		'dialogue_puzzle1_02_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle1_02_03'

		},
		'dialogue_puzzle1_02_03':{
			speakingCharacter: 0,
		},
		'dialogue_puzzle1_02_04':{
			speakingCharacter: 0,
		},

	}
};

export default dialogue_puzzle1_02;