import { createGlobalStyle } from 'styled-components';
import Theme from './theme';
import fonts from '../assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`

	${fonts}

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;	
		font-family: 'Poppins', sans-serif;
		color: ${Theme.colors.neutralDarkest};
		font-size: 15px;
	}

	p, h1, h2{
		margin:0;

		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: 1.5em;
		}
	}

	h2 {
		font-size: 2em;
		font-weight: 700;
		color: ${Theme.colors.primary};

		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: 1.5em;
		}
	}

	h3 {
		& > b {
			font-weight: 700;
			color: ${p => p.theme.colors.primary}
		}
	}

	p {
		font-size: 1em;
		margin-bottom: 1em;
	}

	button {
		font-family: 'Poppins', sans-serif;
		font-weight: 700;
		font-size: 1em;
		padding-block: 0.4em;
		line-height:22px;
	}

	body {

		margin:0;
		padding:0;
		height: 100%;
        overflow: hidden;

	}

	* {
		box-sizing: border-box;
        user-select: none;
	}

	#root {
		height: 100%;
	}

	/* custom scroll bar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

`;

export default GlobalStyle;
