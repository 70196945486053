import { useAtom } from 'jotai';
import { activePuzzleAtom, puzzleStepAtom } from '../../../atoms/puzzle';
import { gameFlowStepAtom } from '../../../atoms/three';


const DCPuzzle = () => {

	const [step,setStep] = useAtom(puzzleStepAtom);
	const [activePuzzle, setActivePuzzle] = useAtom(activePuzzleAtom);
	const [flow, setFlowStep] = useAtom(gameFlowStepAtom);

	return(
		<>
			step: <input onChange={(e) => setStep(parseInt(e.target.value))} value={step} type="number"/>
			puzzle: <input onChange={(e) => setActivePuzzle(parseInt(e.target.value))} value={activePuzzle} type="number"/>
			flow step: <input onChange={(e) => setFlowStep(parseInt(e.target.value))} value={flow} type="number"/>

		</>
	);
};
 
export default DCPuzzle;