import { atom } from 'jotai';
import { EventQueuePayloadBasic, EventType } from './eventQueue';
import { Callback } from 'i18next';

/**
	* If not undefined, enables porto with dialogue that will be activated when clicked
	*/
export const enablePortoWithDialogueAtom = atom('');

export const activeDialogueAtom = atom('');
export const activeMessageAtom = atom('');

export type DialogueData = {
	messages: {[key: string]: MessageData},
	name: string, //character name that displays above dialogue
	callbacks?:  CallbackActions[]//executes when this dialogue closes
	lookat?: {
		azimuthAngle: number,
		polarAngle: number,
	}
}

export type CallbackActions = CallbackSetPuzzleStep | CallbackQueueEvent

type CallbackSetPuzzleStep = {
	type: 'set-step',
	payload: {stepId: number}
}

type CallbackQueueEvent = {
	type: 'queue-event',
	payload: EventQueuePayloadBasic
	immediate?: boolean
}


export type MessageData = {
	// text: string,
    followupMessage?: string, // index for the next message. used when there are no answers. 
	defaultAnswer? : number, // index of the answer that is selected by default when the timer runs out.
	speakingCharacter: 0 | -1
	answers?: Answer[] 
}

export type Answer = {
	/**
	 * References a message to load when this answer is selected.
	*/
	followupMessage?: string,
	callbacks?: CallbackActions[]
}
