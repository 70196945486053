import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle2_01: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle2_01_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle2_01_01'
		},

		'dialogue_puzzle2_01_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_puzzle2_01_02'
		},
		'dialogue_puzzle2_01_02':{
			speakingCharacter: 0,
		},


	}
};

export default dialogue_puzzle2_01;