import { useAtom, useAtomValue, } from 'jotai';
import { activeMovementPositionIdAtom, controlsAtom, debugCameraAtom, debugPerformanceAtom } from '../../../atoms/three';

const DCGeneral = () => {

	const [debugCamera, setDebugCamera] = useAtom(debugCameraAtom);
	const [debugPerformance, setDebugPerformance] = useAtom(debugPerformanceAtom);

	const [currentPositionId, setCurrentPositionId] = useAtom(activeMovementPositionIdAtom);
	const controls = useAtomValue(controlsAtom);

	return ( 
		<>
			<label> debug camera
				<input type={'checkbox'} checked={debugCamera} onChange={(e) => setDebugCamera(e.target.checked)}/>
			</label>
			<label> debug performance
				<input type={'checkbox'} onChange={(e) => setDebugPerformance(e.target.checked)} checked={debugPerformance}/>
			</label>
			<label> active movement position
				<input type={'text'} onChange={(e) => setCurrentPositionId(e.target.value)} value={currentPositionId}/>
			</label>
			<button onClick={() => {
				console.log(
					`%c azimuth angle: ${controls?.azimuthAngle}`, 
					'background-color: cyan; color: black;'
				);
				console.log(
					`%c polar angle: ${controls?.polarAngle}`, 
					'background-color: yellow; color: black;'
				);
			}}
			>
				log camera angles
			</button>
		</>
	);
};
 
export default DCGeneral;