import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_06: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'queue-event', payload:{eventType: 'proceed-gameflow', parameter: '9'}, immediate: true},
	],
	messages: { 
		
		// start message         
		'dialogue_car_06_00':{
			speakingCharacter: -1,
			followupMessage: 'dialogue_car_06_01'
		},
		'dialogue_car_06_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_06_02'
		},
		'dialogue_car_06_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_06_03'
		},
		'dialogue_car_06_03':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_car_06_04'},
				{followupMessage: 'dialogue_car_06_05'},
				{followupMessage: 'dialogue_car_06_06'},			
				{followupMessage: 'dialogue_car_06_08', callbacks: []},
			]

		},
		
		'dialogue_car_06_04':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_06_03'
		},
		
		'dialogue_car_06_05':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_06_07'
		},
		
		'dialogue_car_06_06':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_06_03'
		},

		'dialogue_car_06_07':{
			speakingCharacter: 0,
		},

		'dialogue_car_06_08':{
			speakingCharacter: -1,
		},
		
	}
};

export default dialogue_car_06;