import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_04: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{
			type: 'queue-event',
			payload: {
				eventType: 'proceed-gameflow',
				parameter: '7'
			},
			immediate: true

		}
	],
	messages: { 
		
		// start message         
		'dialogue_car_04_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_04_01'
		},	
		'dialogue_car_04_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_04_02'
		},	
		'dialogue_car_04_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_04_03'
		},	
		'dialogue_car_04_03':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_04_04'
		},	
		'dialogue_car_04_04':{
			speakingCharacter: 0,
		},	
	}
};

export default dialogue_car_04;