import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle2_03: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle2_03_00':{
			speakingCharacter: 0,
		},

	}
};

export default dialogue_puzzle2_03;