import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle3_04: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'set-step', payload:{stepId: 2}},
	],
	messages: { 
		
		// start message         
		'dialogue_puzzle3_04_00':{
			speakingCharacter: -1,
			followupMessage: 'dialogue_puzzle3_04_01'
		},
		'dialogue_puzzle3_04_01':{
			speakingCharacter: 0,
		},


	}
};

export default dialogue_puzzle3_04;