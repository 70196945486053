import { DialogueData } from '../../atoms/dialogue';
import dialogue_car_01 from './dialogue_car_01';
import dialogue_puzzle3_01 from './dialogue_puzzle3_01';
import dialogue_puzzle1_01 from './dialogue_puzzle1_01';
import dialogue_puzzle1_02 from './dialogue_puzzle1_02';
import dialogue_puzzle1_04 from './dialogue_puzzle1_04';
import dialogue_car_02 from './dialogue_car_02';
import dialogue_car_03 from './dialogue_car_03';
import dialogue_car_08 from './dialogue_car_08';
import dialogue_car_04 from './dialogue_car_04';
import dialogue_car_05 from './dialogue_car_05';
import dialogue_car_06 from './dialogue_car_06';
import dialogue_car_07 from './dialogue_car_07';
import dialogue_puzzle1_03 from './dialogue_puzzle1_03';
import dialogue_puzzle1_05 from './dialogue_puzzle1_05';
import dialogue_puzzle2_01 from './dialogue_puzzle2_01';
import dialogue_puzzle2_02 from './dialogue_puzzle2_02';
import dialogue_puzzle2_03 from './dialogue_puzzle2_03';
import dialogue_puzzle3_02 from './dialogue_puzzle3_02';
import dialogue_puzzle3_03 from './dialogue_puzzle3_03';
import dialogue_exitcar_01 from './dialogue_exitcar_01';
import dialogue_puzzle3_04 from './dialogue_puzzle3_04';
import dialogue_puzzle3_05 from './dialogue_puzzle3_05';
import dialogue_puzzle2_06 from './dialogue_puzzle2_06';
import dialogue_puzzle2_05 from './dialogue_puzzle2_05';
import dialogue_puzzle2_04 from './dialogue_puzzle2_04';

const dialogues: {[key: string]: DialogueData} = {


	'dialogue_car_01': dialogue_car_01,
	'dialogue_car_02': dialogue_car_02,
	'dialogue_car_03': dialogue_car_03,
	'dialogue_car_04': dialogue_car_04,
	'dialogue_car_05': dialogue_car_05,
	'dialogue_car_06': dialogue_car_06,	
	'dialogue_car_07': dialogue_car_07,
	'dialogue_car_08': dialogue_car_08,
	'dialogue_exitcar_00' : dialogue_exitcar_01,
	
	'dialogue_puzzle1_01': dialogue_puzzle1_01,
	'dialogue_puzzle1_02': dialogue_puzzle1_02,
	'dialogue_puzzle1_03': dialogue_puzzle1_03,
	'dialogue_puzzle1_04': dialogue_puzzle1_04,
	'dialogue_puzzle1_05': dialogue_puzzle1_05,

		
	'dialogue_puzzle2_01': dialogue_puzzle2_01,
	'dialogue_puzzle2_02': dialogue_puzzle2_02,
	'dialogue_puzzle2_03': dialogue_puzzle2_03,
	'dialogue_puzzle2_04': dialogue_puzzle2_04,
	'dialogue_puzzle2_05': dialogue_puzzle2_05,
	'dialogue_puzzle2_06': dialogue_puzzle2_06,

	'dialogue_puzzle3_01': dialogue_puzzle3_01,
	'dialogue_puzzle3_02': dialogue_puzzle3_02,
	'dialogue_puzzle3_03': dialogue_puzzle3_03,
	'dialogue_puzzle3_04': dialogue_puzzle3_04,
	'dialogue_puzzle3_05': dialogue_puzzle3_05,

	'wrong_button_puzzle_01' : {name: 'dispatch', messages: {'wrong_button_puzzle_01': {speakingCharacter: 0}}},
	'wrong_button_puzzle_02' : {name: 'dispatch', messages: {'wrong_button_puzzle_02': {speakingCharacter: 0}}},
	'wrong_button_puzzle_03' : {name: 'dispatch', messages: {'wrong_button_puzzle_03': {speakingCharacter: 0}}},
	'wrong_button_puzzle_04' : {name: 'dispatch', messages: {'wrong_button_puzzle_04': {speakingCharacter: 0}}},
	'wrong_button_puzzle_05' : {name: 'dispatch', messages: {'wrong_button_puzzle_05': {speakingCharacter: 0}}},
	'wrong_button_puzzle_06' : {name: 'dispatch', messages: {'wrong_button_puzzle_06': {speakingCharacter: 0}}},
	'wrong_button_puzzle_07' : {name: 'dispatch', messages: {'wrong_button_puzzle_07': {speakingCharacter: 0}}},
	'wrong_button_puzzle_08' : {name: 'dispatch', messages: {'wrong_button_puzzle_08': {speakingCharacter: 0}}},
	'wrong_button_puzzle_09' : {name: 'dispatch', messages: {'wrong_button_puzzle_09': {speakingCharacter: 0}}},
	'wrong_button_puzzle_10' : {name: 'dispatch', messages: {'wrong_button_puzzle_10': {speakingCharacter: 0}}},
	
	'wrong_switch_puzzle_01' : {name: 'dispatch', messages: {'wrong_switch_puzzle_01': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_02' : {name: 'dispatch', messages: {'wrong_switch_puzzle_02': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_03' : {name: 'dispatch', messages: {'wrong_switch_puzzle_03': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_04' : {name: 'dispatch', messages: {'wrong_switch_puzzle_04': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_05' : {name: 'dispatch', messages: {'wrong_switch_puzzle_05': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_06' : {name: 'dispatch', messages: {'wrong_switch_puzzle_06': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_07' : {name: 'dispatch', messages: {'wrong_switch_puzzle_07': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_08' : {name: 'dispatch', messages: {'wrong_switch_puzzle_08': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_09' : {name: 'dispatch', messages: {'wrong_switch_puzzle_09': {speakingCharacter: 0}}},
	'wrong_switch_puzzle_10' : {name: 'dispatch', messages: {'wrong_switch_puzzle_10': {speakingCharacter: 0}}},


};

export default dialogues;