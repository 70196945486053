import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import GlobalStyle from './global';

const CustomThemeProvider = (props: {children: ReactNode}) => {
	return(
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			{props.children}
		</ThemeProvider>
	);
};

export default CustomThemeProvider;