const theme = {
	colors:{
		neutralLightestT: 'rgba(255, 255, 255, 0.85)',
		neutralLightest: '#FFFFFF', // white
		neutralLighter: '#d9d9d9', // light grey
		neutral: '#9e9e9e', // grey
		neutralDarker: 'hsl(0, 0%, 50%)',
		neutralDarkest: '#000000', // black
		neutralDarkestT: 'hsl(250deg 0% 0% / .85)',

		primary: 'hsl(359, 84%, 45%)', //#d51317
		primaryDarker: 'hsl(0, 100%, 32%)',
		primaryDarkest: 'hsl(0, 100%, 22%)',
		secondary: '#FF3228',
		secondaryDarker: '#c9b0a1',

		tertiary: 'hsl(203, 62%, 71%)',

		danger: 'hsl(356, 100%, 68%)',	
		correct: 'hsl(122, 33%, 57%)',	
		alert: 'hsl(38, 99%, 58%)',	
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 10,
			sm: 15,
			lg: 25,
			xl: 25,
		},
		fontSize: {
			xs: '.8em',
			sm: '.8em',
			lg: '1.2em',
			xl: '1.4em',
		}
	},
	properties: {
		borderRadius: '20px',
		borderWidth: '0.25em'
	}
};

export default theme;
