import { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const Game = lazy(() => import('./games'));

export const App = () => {

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Game/>}/>
					{/* redirect routes that do no exist to '/' */}
					<Route path="*" element={<Navigate to="/" replace/>}/> 
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
