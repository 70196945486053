import dialogueTranslations from './dialogue/dialogueTranslations';

export default {
	'general': {
	},
	'pages': {
		'introduction': {
			'title': ''
		},
		'game': {
			'name_label_player' : 'Jij',
			'intro_title': 'Introductie',
			'intro_description': 'Welkom bij de RWG-game over het Fence Control System! Om rond te kijken in de omgeving klik en sleep je de muis of swipe je op je telefoon. Voorwerpen die rood omcirkeld zijn kan je aanklikken. Wanneer de portofoon rechts onder in beeld komt, kan je er op klikken om contact te leggen met de dispatcher.',
			'end_title': 'Einde',
			'end_description': 'Je hebt succesvol deze kennismaking met het Fence Control System doorlopen. Duik in het RWG platform en leer alles wat je moet weten over het FCS. Veel succes! Klik op het kruisje om de ervaring te sluiten.'
		}
	},
	'content': {
		'dialogue': dialogueTranslations
	}
};