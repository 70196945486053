import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DCGeneral from './tabs/DCGeneral';
import DCDialogue from './tabs/DCDialogue';
import DCLocalization from './tabs/DCLocalization';
import DCPuzzle from './tabs/DCPuzzle';
import DCEventQueue from './tabs/DCEventQueue';

import packageJson from '../../../package.json';

const tabs : {[key:string]: () => JSX.Element}  = {
	'general': DCGeneral,
	'dialogue': DCDialogue,
	'localization': DCLocalization,
	'puzzle' : DCPuzzle,
	'event queue': DCEventQueue,
};

// components

export const DeveloperConsole = () => {

	const [DCActive, setDCActive] = useState<boolean>();
	const [activeTab, setActiveTab] = useState<string>();

	const DCComponent = useMemo(() => {
		if(!activeTab || !tabs[activeTab]) return;

		return tabs[activeTab];
	},[activeTab]);

	//#region opening the console

	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		if(event.altKey && event.code === 'KeyQ'){
			setDCActive(a =>!a);
		}
	}, []);

	// attach the event listener
	useEffect(() => {
		// attach the event listener
		document.addEventListener('keydown', handleKeyPress);
		
		// remove the event listener
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	//#endregion

	//#region caching

	const [cacheLoaded, setCacheLoaded] = useState<boolean>(false);

	// load last state of the console.
	useEffect(() => {
		setCacheLoaded(true);
		const data = localStorage.getItem('DCData');
		if(!data) return;

		const parsed = JSON.parse(data);

		setDCActive(parsed.active);
		setActiveTab(parsed.tab);
	},[]);

	// save the last state of the console to the localStorage
	useEffect(() => {
		if(!cacheLoaded) return;
		localStorage.setItem('DCData', JSON.stringify({active: DCActive, tab: activeTab}));
	},[DCActive, activeTab]);

	//#endregion

	return (
		<>
			{ DCActive && 
				<Container>
					<Tabs> 
						{Object.keys(tabs).map((tab, index) => 
							<button 
								key={`dc-tab-${index}`}
								onClick={() => setActiveTab(a => a === tab ? '' : tab)}
							>{tab}</button>
						)}
					</Tabs>
					{DCComponent &&
						<TabContainer>
							<DCComponent/>
						</TabContainer>
					}
					<div style={{position: 'absolute', right: '20px'}}>version: {packageJson.version}</div>
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	left:0;top:0;right:0;

	overflow: auto;

	background-color: rgba(0,0,0,0.6);

	// flex

	display: flex;
	flex-direction: column;
	gap: 20px;

	padding: 20px;

	color: white;

	z-index: 9002;
`;

const TabContainer = styled.div`
	display: flex;
	flex-direction: row;
    gap: 10px;
`;

const Tabs = styled.div`
	
	// flex

	display: flex;
	flex-direction: row;

`;