import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle2_04: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle2_04_00':{
			speakingCharacter: 0,
			// followupMessage: 'dialogue_puzzle2_04_01'
		},
	}
};

export default dialogue_puzzle2_04;