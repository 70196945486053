import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_08: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_car_08_00':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_car_08_01'},
				{followupMessage: 'dialogue_car_08_01'},
			]
		},
		'dialogue_car_08_01':{
			speakingCharacter: 0,
		},

	}
};

export default dialogue_car_08;