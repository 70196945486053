import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle3_01: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle3_01_00':{
			speakingCharacter: 0,
			// followupMessage: 'dialogue_puzzle3_01_01'
		},
		// 'dialogue_puzzle3_01_01':{
		// 	speakingCharacter: 0,
		// },
	}
};

export default dialogue_puzzle3_01;