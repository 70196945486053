const dialogueTranslations = {

	//dialogue_car_1

	'dialogue_car_01_00': 'Hey Mitchell hier van dispatch. Gefeliciteerd met je nieuwe functie bij RWG!',
	'dialogue_car_01_01': 'Ik heb meteen een leuke klus voor je op je eerste dag.',
	'dialogue_car_01_02': 'Er is een storing bij AGV501, kan ik dat aan jou overlaten?',

	// answer 1
	'dialogue_car_01_02_a1': 'Ja, dat is voor mij gesneden koek!',
	'dialogue_car_01_03': 'Oké, top! Mocht je het toch niet helemaal meer weten, dan kun je mij via de portofoon bereiken.',

	// answer 2
	'dialogue_car_01_02_a2': 'Hmm, daar weet ik nog niet veel van',
	'dialogue_car_01_04': 'Geen probleem, ik kijk met je mee! Mocht je hulp nodig hebben, dan kun je mij via de portofoon bereiken.',
	
	'dialogue_car_01_05':'Voor nu kun je vertrekken naar de servicelane bij module 11.',
	'dialogue_car_01_06': 'Oké, ik ben al onderweg',
	// 'dialogue_car_01_07':'Trouwens, het is hier zo groot. Als je om je heen wil kijken klik en sleep je het beeld.', 
	// 'dialogue_car_01_08':'Objecten met een rode rand kun je aanklikken om verder te komen.',

	// end dialogue_car_1

	// dialogue car 02
	'dialogue_car_02_00': 'Om het hek van de servicelaan te openen heb je een booster nodig. Deze zit in de auto, tegen het raam. Die stuurt een signaal naar de paal.',

	'dialogue_car_02_01': 'Met je persoonlijke pas in de booster kan je het hek openen.',
	// 'dialogue_car_02_02': 'Met je persoonlijke pas in de booster kan je het hek openen.',

	//end dialogue car 02


	//dialogue_car_3
	'dialogue_car_03_00': 'Nog een tip: het gebruik van de booster lukt soms niet als je niet ver genoeg rijdt richting het hek van de servicelaan. ',
	'dialogue_car_03_01': 'Je moet dan verder doorrijden, voordat het hek opent. Trouwens, weet je al wat over het Fence Control System? Die gaan we nu namelijk inzetten.',
	
	'dialogue_car_03_01_a1': 'Ja, dat weet ik wel',
	'dialogue_car_03_02': 'Ik ga het toch nog een keer herhalen, just to be sure.',
	
	'dialogue_car_03_01_a2': 'Nee, eigenlijk niet',
	'dialogue_car_03_03': 'Met het Fence Control System zorgen we ervoor dat we op een veilige manier areas met onze AGV’s kunnen betreden.',
	'dialogue_car_03_04': 'Bijvoorbeeld bij een storing zoals vandaag.',
	'dialogue_car_03_05': 'Wacht, kijk even in de map met de vaste Fence Control gebieden naast je.',
	//end dialogue_car_3


	//dialogue_car_4
	'dialogue_car_04_00': 'Bij zo’n storing als vandaag activeren wij een restricted area waar AGV\'s niet mogen komen',
	'dialogue_car_04_01': 'Om de veiligheid te garanderen wordt er buiten deze veilige core area een border area ingesteld',
	'dialogue_car_04_02': 'Dit is het gebied dat een AGV nodig heeft om tot stilstand te komen.',
	'dialogue_car_04_03': 'Daarbuiten hebben we nog een reduced speed area waarin de AGV’s met aangepaste snelheid rijden.',
	'dialogue_car_04_04': 'Op die manier voorkomen we dat er onveilige situaties ontstaan.',

	//end dialogue_car_4


	//dialogue_car_5
	'dialogue_car_05_00': 'Ik weet nog op mijn eerste werkdag, ik opende zonder een FCS-gebied een hek waardoor er een noodstop op alle AGV’s viel.',
	'dialogue_car_05_01': 'Zo’n fout maak ik geen tweede keer.',

	//end dialogue_car_5

	//dialogue_car_6
	'dialogue_car_06_00': 'Hey Mitchell, ik wil graag een restricted area aanvragen.',
	'dialogue_car_06_01': 'Dat is goed, ik activeer een gebied aan de hand van de positie van de AGV en de operatie.',
	'dialogue_car_06_02': 'In de map op de volgende pagina vind je een overzicht van alle gebieden die mogelijk zijn.',
	'dialogue_car_06_03': 'Welke heb ik voor je geactiveerd denk je?',

	'dialogue_car_06_04': 'Die area ga ik niet voor je instellen. Die lijkt me wel heel groot, dan hebben we wel erg veel downtime.',
	'dialogue_car_06_05': 'Gebied ID 47 heb ik inderdaad voor je geactiveerd. Die is groot genoeg om bij de AGV te komen, maar niet zo groot dat alle AGV’s stil komen te staan.',
	'dialogue_car_06_06': 'Die area ga ik niet voor je instellen. Die is wel erg klein, dan kom je nooit op een veilige manier bij de AGV.',
	
	'dialogue_car_06_07': 'Tijd om naar de slagboom te gaan!',
	'dialogue_car_06_08': 'Ik kijk nog even in de map, geef me even hoor.',

	'dialogue_car_06_03_a1': 'ID 15',
	'dialogue_car_06_03_a2': 'ID 47',
	'dialogue_car_06_03_a3': 'ID 75',
	'dialogue_car_06_03_a4': 'Map bekijken',

	//end dialogue_car_6

	//dialogue_car_7
	'dialogue_car_07_00': 'Hey, vergeet je niet iets?',
	'dialogue_car_07_01': 'Safety first! Dus altijd een helm op voordat je het terrein op gaat.',

	'dialogue_car_07_00_a1': 'Volgens mij niet',
	'dialogue_car_07_00_a2': 'Ik denk van wel',
	
	//end dialogue_car_7

	'dialogue_exitcar_01_00':'Je kan nu naar de kast toe bij de slagboom. Help ik je verder.',


	//dialogue_car_8
	'dialogue_car_08_00':'Hey, vergeet je niet iets?',
	'dialogue_car_08_01': 'Grapje! Ik testte even of je wel aan de safety dacht, maar je bent geslaagd. Goed dat je je helm al hebt gepakt.',

	'dialogue_car_08_00_a1': 'Volgens mij niet',
	'dialogue_car_08_00_a2': 'Ik denk van wel',
		
	//end dialogue_car_8

	//dialogue_puzzle1_1
	'dialogue_puzzle1_01_00':'Nou, daar is ‘ie dan, de knoppenkast. Best veel te zien hé? Duizelt het al?',
		
	'dialogue_puzzle1_01_00_a1': 'Een beetje wel ja',
	'dialogue_puzzle1_01_00_a2': 'Nee, dit kan ik wel overzien',
		
	'dialogue_puzzle1_01_01': 'Ik begrijp je helemaal. Ik weet nog toen ik net begon bij RWG en voor het eerst het hek moest openen bij module 8, dat was geen succes...',
	'dialogue_puzzle1_01_02': 'Heel goed! Ik weet nog toen ik net begon bij RWG en voor het eerst het hek moest openen bij module 8, dat was geen succes...',

	'dialogue_puzzle1_01_03':'Ik heb nog wekenlang mijn collega’s moeten aanhoren over hoe ik stond te stuntelen bij die kast.',

	'dialogue_puzzle1_01_04': 'Ik heb de restricted area aangevraagd. Nu moeten alle AGV’s terugkoppeling geven aan het systeem dat het gebied in aanvraag is.',
	'dialogue_puzzle1_01_05': 'Dat kun je zien aan de gele knop die knippert. Zie je die?',

	'dialogue_puzzle1_01_05_a1': 'Ja, ik zie ‘m knipperen.',
	'dialogue_puzzle1_01_05_a2': 'Nee, ik zie ‘m niet knipperen.',

	'dialogue_puzzle1_01_06': 'Mooi hé, hoe die systemen met elkaar samenwerken.',
	'dialogue_puzzle1_01_07': 'Ik geloof dat ‘ie bij deze kast rechtsonder zit.',

	'dialogue_puzzle1_01_08' : 'Zodra alles is goedgekeurd stopt de lamp met knipperen en blijft ‘ie geel branden. Daarna kunnen we verder',

	//end dialogue_puzzle1_1

	//dialogue_puzzle1_2

	'dialogue_puzzle1_02_00': 'Geduld is een schone zaak, vooral als het om veiligheid gaat. Het gebied is nu actief.',
	'dialogue_puzzle1_02_01': 'Dat zie je aan de gele lamp die blijft branden. Weet je nu hoe je het verder moet instellen?',
	'dialogue_puzzle1_02_01_a1': 'Nee, kan je me helpen?',
	'dialogue_puzzle1_02_01_a2': 'Ja, dat weet ik',

	'dialogue_puzzle1_02_02': 'Als eerste moet je de juiste schakelaar beveiligen, zo weten je collega’s dat je bezig bent en kan niemand het gebied verwijderen.',
	'dialogue_puzzle1_02_03' : 'Deze zit rechtsonder. Je draait eerst de knop om en bevestigt dan het klepje. Daarna zet je je persoonlijke slot erop.',
	'dialogue_puzzle1_02_04': 'Succes!',

	//end dialogue_puzzle1_2

	//dialogue_puzzle1_3
	'dialogue_puzzle1_03_00' : 'Ik kom er niet helemaal uit, kan je me helpen?',
	'dialogue_puzzle1_03_01' : 'Tuurlijk! Goed dat je het vraagt. Je moet eerst de juiste schakelaar omzetten, daarna kun je de schakelaar beveiligen met je persoonlijke slot.',


	//dialogue_puzzle1_3

	//dialogue_puzzle1_4
	'dialogue_puzzle1_04_00' : 'Gelukt! Lekker bezig. Weet je hoe je verder moet nu?',
	'dialogue_puzzle1_04_00_a1' : 'Een beetje hulp zou fijn zijn.',

	'dialogue_puzzle1_04_01' : 'Je hebt net de schakelaar beveiligd. Druk de gele knop in die geel brandt om de slagboom te openen.',


	//dialogue_puzzle1_4

	
	//dialogue_puzzle1_5

	'dialogue_puzzle1_05_00' : 'Kijk aan! Je hebt de slagboom achter je geopend en kan nu op een veilige manier naar de area toe.',
	'dialogue_puzzle1_05_01' : 'Als je AGV501 hebt gefixt, pak de portofoon er weer even bij en laat me weten dat je teruggaat.',

	//end dialogue_puzzle1_5


	//dialogue_puzzle2_1

	'dialogue_puzzle2_01_00' : 'Kijk! AGV’s rijden nu om het gebied met aangepaste snelheid, zodat we ons veilig kunnen verplaatsen.',
	'dialogue_puzzle2_01_01' : 'Over AGV’s gesproken, daar voor je staat AGV501 die de storing aangeeft.',
	'dialogue_puzzle2_01_02':' Kan je even kijken op de display wat er aan de hand is?',

	//end dialogue_puzzle2_1


	//dialogue_puzzle2_2

	'dialogue_puzzle2_02_00' : 'Hey pas op! Dat is niet het veilige gebied wat ik voor je heb aangevraagd.',
	'dialogue_puzzle2_02_01' : 'Blijf binnen de core area. In de andere area’s rijden de AGV’s namelijk nog.',
	
	//end dialogue_puzzle2_2

	//dialogue_puzzle2_3
	'dialogue_puzzle2_03_00' : 'Zie je toevallig een rood vakje? Daar zit de storing in. Kan je daar op drukken?',
	//end dialogue_puzzle2_3

	//dialogue_puzzle2_4
	'dialogue_puzzle2_04_00' : 'Nu kan je op de knop NS Fout drukken.',
	//end dialogue_puzzle2_4

	//dialogue_puzzle2_5
	'dialogue_puzzle2_05_00': 'Ah ja, ik zie het al. Als je even op knop 305 klikt dan wordt deze groen en kan AGV501 weer doorgaan.',
	//end dialogue_puzzle2_5
	


	//dialogue_puzzle2_6
	'dialogue_puzzle2_06_00' : 'Ik ben klaar!',

	'dialogue_puzzle2_06_01' : 'Goed gedaan! Je kunt nu terug naar de kast.',
	'dialogue_puzzle2_06_02' : 'Als je daar weer bent geef me dan even een seintje.',

	//end dialogue_puzzle3_6

	//dialogue_puzzle3_1
	'dialogue_puzzle3_01_00' : 'Tijd om de area weer vrij te geven. Eerst maar weer de slagboom dicht doen. Loop even naar de kast.',
	//end dialogue_puzzle3_1

	//dialogue_puzzle3_2
	'dialogue_puzzle3_02_00' : 'Als het goed is brandt de gele knop nog geel. Daar kan je op drukken.',
	//end dialogue_puzzle3_2

	'dialogue_puzzle3_03_00' : 'Zo, die is weer dicht. Heb je je slot al gecheckt?',

	//dialogue_puzzle3_3

	'dialogue_puzzle3_04_00' :'Hey Mitchell, het gebied is weer vrijgegeven en de Restricted Area kan worden verwijderd.',

	'dialogue_puzzle3_04_01' :'Ik heb ‘m voor je verwijderd. Nog even wachten op de controle, is de gele knop gestopt met branden?',
	
	//end dialogue_puzzle3_3

	'dialogue_puzzle3_05_00' :'Ja, hij is weer helemaal uit.',

	'dialogue_puzzle3_05_01' :'Zo, dat was ‘m. Dank voor je hulp vandaag, goed bezig zo op je eerste dag!',
	'dialogue_puzzle3_05_02' :'Zoals je wel gemerkt hebt, is communicatie tussen monteurs en dispatchers erg belangrijk.',
	'dialogue_puzzle3_05_03' :'En het helpt ook om verstand te hebben van het Fence Control System. Safety first!',


	//wrong button dialogues
	'wrong_button_puzzle_01': 'Oeps, dat is niet de juiste knop. Probeer het opnieuw. ',
	'wrong_button_puzzle_02': 'Helaas, met deze knop kan je de slagboom niet openen. ',
	'wrong_button_puzzle_03': 'Bijna goed, maar niet helemaal! Probeer een andere knop. ',
	'wrong_button_puzzle_04': 'Nee, dat is niet de correcte keuze. Probeer het nog eens.',
	'wrong_button_puzzle_05': 'Hm, deze knop lijkt niet te werken voor de slagboom. Probeer een andere.',
	'wrong_button_puzzle_06': 'Niet de goede knop! Geef niet op, zoek de juiste knop. ',
	'wrong_button_puzzle_07': 'Dat is helaas niet de juiste optie. Probeer iets anders. ',
	'wrong_button_puzzle_08': 'Helaas, de slagboom reageert niet op deze knop. Zoek de juiste. ',
	'wrong_button_puzzle_09': 'Bijna, maar niet helemaal! Probeer een andere knop om de slagboom te openen. ',
	'wrong_button_puzzle_10': 'Dat werkt niet, je moet de juiste knop zien te vinden om de slagboom te openen. ',

	//wrong switches dialogues
	'wrong_switch_puzzle_01': 'Helaas, dat is niet de juiste schakelaar. Probeer een andere. ',
	'wrong_switch_puzzle_02': 'Oeps, met deze schakelaar gebeurt er niets. Kies een andere. ',
	'wrong_switch_puzzle_03': 'Nee, dat is niet de juiste keuze. Probeer een andere schakelaar. ',
	'wrong_switch_puzzle_04': 'Hm, deze schakelaar lijkt niet te werken. Probeer een andere te vinden. ',
	'wrong_switch_puzzle_05': 'Dat is niet de juiste schakelaar voor deze actie. Probeer opnieuw. ',
	'wrong_switch_puzzle_06': 'Bijna goed, maar niet helemaal! Zoek de juiste schakelaar om verder te gaan. ',
	'wrong_switch_puzzle_07': 'Helaas, deze schakelaar doet niet wat je wilt. Probeer een andere optie. ',
	'wrong_switch_puzzle_08': 'Niet de goede schakelaar! Ga op zoek naar de juiste om te kunnen doorgaan. ',
	'wrong_switch_puzzle_09': 'Dat werkt niet, probeer een andere schakelaar om de gewenste actie uit te voeren. ',
	'wrong_switch_puzzle_10': 'Bijna, maar je moet de juiste schakelaar vinden om verder te kunnen. Blijf proberen! ',
};

export default dialogueTranslations;