import { useMemo } from 'react';
import { gameData } from '../../../data/game';
import { useAtom } from 'jotai';
import { activeDialogueAtom, activeMessageAtom, enablePortoWithDialogueAtom } from '../../../atoms/dialogue';
import { useTranslation } from 'react-i18next';


const DCDialogue = () => {

	const [enableDialogue, setEnabledDialogue] = useAtom(enablePortoWithDialogueAtom);
	const [activeDialogue, setActiveDialogue] = useAtom(activeDialogueAtom);
	const [activeMessage, setActiveMessage] = useAtom(activeMessageAtom);

	return(
		<>
			<label>dialogue: </label>
			{gameData.dialogues && <select onChange={(e) => setActiveDialogue(e.currentTarget.value)} value={activeDialogue}>
				<option value={''}>none</option>
				{Object.keys(gameData.dialogues).map((key, index) => {
					return <option key={index} value={key}>{key}</option>;
				})}
			</select>}
			<label>message: </label>
			{activeDialogue && gameData.dialogues[activeDialogue].messages && <select onChange={(e) => setActiveMessage(e.currentTarget.value)} value={activeMessage}>
				<option value={''}>none</option>
				{Object.keys(gameData.dialogues[activeDialogue].messages).map((key, index) => {
					return <option key={index} value={key}>{key}</option>;
				})}
			</select>}
		</>
	);
};
 
export default DCDialogue;