import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_07: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_car_07_00':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_car_07_01'},
				{followupMessage: 'dialogue_car_07_01'},
			]
		},
		'dialogue_car_07_01':{
			speakingCharacter: 0,
		},
		// 'dialogue_car_07_02':{
		// 	speakingCharacter: 0,
		// },
	}
};

export default dialogue_car_07;