import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_03: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'queue-event', payload:{eventType: 'proceed-gameflow', parameter: '5'}, immediate: true},
	],
	lookat: {
		azimuthAngle: 4.605,
		polarAngle: 1.551
	},
	messages: { 
		
		// start message         
		'dialogue_car_03_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_03_01',
		},
		'dialogue_car_03_01':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_car_03_02'},
				{followupMessage: 'dialogue_car_03_03'},
			]				},
		'dialogue_car_03_02':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_03_03'
		},		
		'dialogue_car_03_03':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_03_04'
		},		
		'dialogue_car_03_04':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_03_05'
		},		
		'dialogue_car_03_05':{
			speakingCharacter: 0,
		},		
	}
};

export default dialogue_car_03;