import { DialogueData } from '../../atoms/dialogue';

const dialogue_exitcar_01: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_exitcar_01_00':{
			speakingCharacter: 0,
		},	
	}
};

export default dialogue_exitcar_01;