import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import Plausible from 'plausible-tracker';
import CustomThemeProvider from './style/CustomThemeProvider';
import { DeveloperConsole } from './components/console/DeveloperConsole';

// analytics

const { trackPageview } = Plausible({
	domain:'rwgfencecontrol.tqwi.nl',
	hashMode: true,
});

trackPageview({
	url: location.hostname,
});

// react

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<DeveloperConsole/>
		<CustomThemeProvider>
			<App/>
		</CustomThemeProvider>
	</React.StrictMode>
);
