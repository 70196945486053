import { DialogueData } from '../../atoms/dialogue';

const dialogue_puzzle1_04: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_puzzle1_04_00':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_puzzle1_04_01'}
			]
		},

		'dialogue_puzzle1_04_01':{
			speakingCharacter: 0,
		},
	}
};

export default dialogue_puzzle1_04;