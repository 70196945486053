import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_02: DialogueData = {
	name: 'Dispatch',
	messages: { 
		
		// start message         
		'dialogue_car_02_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_02_01',
		},
		'dialogue_car_02_01':{
			speakingCharacter: 0,
			// followupMessage: 'dialogue_car_02_02',
		},		
		// 'dialogue_car_02_02':{
		// 	speakingCharacter: 0,
		// },	
	}
};

export default dialogue_car_02;