import { DialogueData } from '../../atoms/dialogue';

const dialogue_car_01: DialogueData = {
	name: 'Dispatch',
	callbacks: [
		{type: 'queue-event', payload:{eventType: 'proceed-gameflow', parameter: '2'}, immediate: true},
	],
	messages: { 
		'dialogue_car_01_00':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_01_01'
		},	
		'dialogue_car_01_01':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_01_02'
		},	
		// start message         
		'dialogue_car_01_02':{
			speakingCharacter: 0,
			answers: [
				{followupMessage: 'dialogue_car_01_03'},
				{followupMessage: 'dialogue_car_01_04'},
			]		
		},	
		'dialogue_car_01_03':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_01_05'
		},	
		'dialogue_car_01_04':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_01_05'
		},	

		'dialogue_car_01_05':{
			speakingCharacter: 0,
			followupMessage: 'dialogue_car_01_06'
		},	
		'dialogue_car_01_06':{
			speakingCharacter: -1,
			// followupMessage: 'dialogue_car_01_07'
		},	
		// 'dialogue_car_01_07':{
		// 	speakingCharacter: 0,
		// 	followupMessage: 'dialogue_car_01_08'
		// },	
		// 'dialogue_car_01_08':{
		// 	speakingCharacter: 0,
		// },	
	
	}
};

export default dialogue_car_01;