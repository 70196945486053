import { useAtom, useSetAtom } from 'jotai';
import { eventQueueAtom, EventType} from '../../../atoms/eventQueue';
import { useState } from 'react';
import FIFO from '../../Three/FIFO';
import { FIFOStateAtom } from '../../../atoms/three';

const DCEventQueue = () => {

	const [eventQueue, eventQueueDispatch] = useAtom(eventQueueAtom);

	const [type, setType] = useState<EventType>('toggle-object-visibility');
	const [param, setParam] = useState<string>('');
	const setFIFO = useSetAtom(FIFOStateAtom);
	const onTypeChange = (newType: string) => {
		switch(newType){
		case 'set-movement-position':
			setType('set-movement-position');
			break;
		case 'toggle-object-visibility':
			setType('toggle-object-visibility');
			break;
		}
	};

	return ( 
		<>
			<button onClick={() => setFIFO(1)}>start FIFO</button>
			<div>queue: {JSON.stringify(eventQueue)}</div>
			<label> type
				<select value={type} onChange={(e) => onTypeChange(e.currentTarget.value)} >
					<option value={'toggle-object-visibility'}>
						toggle-object-visibility
					</option>
					<option value={'set-movement-position'}>
						set-movement-position
					</option>
				</select>
			</label>
			<label> param
				<input type={'text'} onChange={(e) => setParam(e.target.value)} value={param}/>
			</label>
			<button onClick={() => eventQueueDispatch({type: 'add', payload: {eventType: type, parameter: param}})}>
				add
			</button>
			<button onClick={() => eventQueueDispatch({type: 'remove', payload: {eventType: type, parameter: param}})}>
				remove
			</button>
			<button onClick={() => eventQueueDispatch({type: 'clear'})}>
				clear
			</button>
		</>
	);
};
 
export default DCEventQueue;